(($) => {
  const nav = document.querySelector('.nav-jump');
  const body = document.body;

  //Guide mask
  const footer = document.querySelector('.site-footer');

  const setBottom = () => {
    const footerBox = footer.getBoundingClientRect();

    const offset = window.innerHeight - footerBox.y;
    if (offset > 0) {
      nav.style.bottom = offset + "px";
    } else if (nav.style.bottom !== "0px") {
      nav.style.bottom = "0px";
    }
  }

  window.addEventListener("resize", setBottom);
  window.addEventListener("scroll", setBottom);
  window.addEventListener("appInitialized", setBottom);

  setBottom();
  
 
  const underlineLinkLastLine = l => {
    const linkTextEl = l.querySelector('span');
    let wordTop = 0;
    let lastLineWords = [];
    let linkTextNode = linkTextEl.firstChild;
    let words = linkTextNode.wholeText.replace(/\n/g,"").split(" ").filter(word => word.length > 0);
    linkTextEl.innerText = words.join(" ");
    linkTextNode = linkTextEl.firstChild;
    let wordNodes = [];
    words.forEach((word,w) => {
      linkTextNode = linkTextNode.splitText(w === 0 ? word.length : word.length+1);
    });
    linkTextEl.childNodes.forEach(text => {
      const range = document.createRange();
      range.selectNode(text);
      const rect = range.getBoundingClientRect();
      range.detach();

      if (rect.top > wordTop) {
        wordTop = rect.top;
        lastLineWords = [];
      }

      lastLineWords.push(text);
    })
    const lastLineEl = document.createElement('span');
    lastLineEl.classList.add('last-line');
    lastLineWords.forEach(word => lastLineEl.appendChild(word));
    linkTextEl.appendChild(lastLineEl);
  }

  //Anchors
  const links = Array.from(nav.querySelectorAll('.nav-link'));
  links.forEach(l => {
    const href = l.href.split("#")[1];
    l.anchor = document.querySelector(`[name="${href}"]`);
    underlineLinkLastLine(l);
  });

  activeAnchor = null;

  let anchorTimeout;

  const setActiveAnchor = () => {
    if (links.length) {
      let l = links.length - 1;
      let activeLink,nextAnchor; 
      do {    
        activeLink = links[l];
        nextAnchor = activeLink.anchor;
        l--;
      } while (l >= 0 && (!nextAnchor || nextAnchor.getBoundingClientRect().y > window.innerHeight/2));
      links.forEach(l => l.classList.remove('active'));
      activeLink.classList.add("active");
      body.classList.remove(`nav-active-${activeAnchor}`);
      activeAnchor = nextAnchor.name;
      body.classList.add(`nav-active-${activeAnchor}`);
    }
  }

  window.addEventListener("resize", setActiveAnchor);
  window.addEventListener("scroll", setActiveAnchor);
  window.addEventListener("appInitialized", setActiveAnchor);

  setActiveAnchor();

})(jQuery)